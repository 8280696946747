import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import logoImage from "../assets/embedded_tucepi.svg"
import { Link } from "gatsby";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
} from "reactstrap";
import { SeiteType } from "./types";
import { useState } from "react";

interface HeaderProps {
  title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  const data: {
    cms: {
      seiten: SeiteType[];
    };
  } = useStaticQuery(graphql`
    query {
      cms {
        seiten: entries(section: "seiten") {
          ... on CMS_seiten_seiten_Entry {
            title
            slug
          }
        }
      }
    }
  `);

  const {
    cms: { seiten },
  } = data;

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar color="inverse" light expand="md">
      <Container>
        <NavbarBrand href="/"><img height="80" src={logoImage} alt="Logo" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link className="nav-link" to="/">Startseite</Link>
            </NavItem>
            {seiten.map((seite: SeiteType) => (
              <NavItem key={seite.slug}>
                <Link className="nav-link" to={"/" + seite.slug}>{seite.title}</Link>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
