import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import "normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Header from "../components/Header";
import { Row, Col, Nav, Container } from "reactstrap";
import Slider, { SliderItems } from "../components/Slider";
import { FamilieType } from "../components/types";


import SimpleReactLightbox from "simple-react-lightbox";
import styled from "styled-components";

const StyledNav = styled((props) => <Nav {...props} />)`
  padding-left:20px;
`;


interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
    };
  };
  cms: {
    familien: FamilieType[];
  };
}

interface DefaultLayoutProps {
  sliderItems?: SliderItems[];
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  sliderItems,
  children,
}) => (
  <StaticQuery
    query={graphql`
      query DefaultLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        cms {
          familien: categories(level: 1, group: "ordnungFamilien") {
            ... on CMS_ordnungFamilien_Category {
              title
              slug
              children {
                title
                slug
              }
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      
      const url = typeof window !== 'undefined' ? window.location.href : '';
      let parentcat = '';
      if(url !== ''){
        parentcat = url.split('/')[4];
      }


      return(
      <>
        <Header title={data.site.siteMetadata.title} />
        {sliderItems && <Slider sliderItems={sliderItems} />}
        <Container className="mt-4">
          <SimpleReactLightbox>
          <Row>
            <Col xs="12" sm="4" md="3" className="d-none d-sm-block">
              <Nav vertical>
                {data.cms.familien.map((familie) => {
                  if(typeof(familie.slug) != 'undefined'){
                    return (
                      <>
                      <Link className="nav-link" key={familie.slug} to={"/kategorie/" + familie.slug}>
                        {familie.title}
                      </Link>
                      {familie.children && familie.slug === parentcat && (
                        <StyledNav vertical>
                        {familie.children.map((subfamilie) => {
                          return (
                            <Link className="nav-link"
                              key={subfamilie.slug}
                              to={"/kategorie/" + familie.slug + "/" + subfamilie.slug}
                            >
                              {subfamilie.title}
                            </Link>
                          );
                        })}
                        </StyledNav>
                      )}
                        
                      </>
                    );
                  }
                })}
              </Nav>
            </Col>
            <Col xs="12" sm="8" md="9">
              {children}
            </Col>
          </Row>
          </SimpleReactLightbox>
        </Container>
      </>
    )}}
  />
);

export default DefaultLayout;
